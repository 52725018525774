import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_SPECIFICATION_CONSTANTS,
  COUPONS_API_CONSTANTS,
  NIFTY_APPLICATIONID_KEY,
} from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { Coupon, Domain } from './../core.types';
import { EntityBaseService } from './entityBase.service';

@Injectable({
  providedIn: 'root',
})
export class CouponsService extends EntityBaseService<Coupon> {
  protected readonly apiUrl = `${environment.apiUrl}/${COUPONS_API_CONSTANTS.coupons}`;
  private readonly BASE_COUPON_API = `${this.apiUrl}/${COUPONS_API_CONSTANTS.actorCoupon}`;
  private readonly BASE_COUPON_MANAGER_API = `${this.apiUrl}/${COUPONS_API_CONSTANTS.actorCouponManager}`;
  private readonly BASE_COUPON_DIRECTORY_API = `${this.apiUrl}/${COUPONS_API_CONSTANTS.actorCouponDirectory}`;

  constructor(protected http: HttpClient) {
    super(http);
  }

  revokeCoupon(couponId: string) {
    return this.http.post(
      `${this.BASE_COUPON_API}/${couponId}/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandRevokeCoupon}`,
      {}
    );
  }

  getCouponById(couponId: string): Observable<Coupon> {
    return this.http.get<Coupon>(
      `${this.apiUrl}/CouponProjector/${couponId}/ask/GetCoupon`
    );
  }

  findCoupon(code: string): Observable<Coupon> {
    return this.http.get<Coupon>(
      `${this.BASE_COUPON_DIRECTORY_API}/!/${API_SPECIFICATION_CONSTANTS.ask}/${COUPONS_API_CONSTANTS.commandFindCoupon}`,
      {
        params: new HttpParams().append('Code', code),
      }
    );
  }

  loadDomains(): Observable<Domain[]> {
    return this.http.get<Domain[]>(
      `${environment.apiUrl}/coupons/coupon-domains`,
      {
        params: {
          ApplicationId: NIFTY_APPLICATIONID_KEY,
          state: 'All',
        },
      }
    );
  }

  loadDomain(id: string): Observable<Domain> {
    return this.http.get<Domain>(
      `${environment.apiUrl}/coupons/coupon-domains/${id}`
    );
  }

  createDomain(data: { Domain: string; Description: string }) {
    return this.http.post<{ id: string }>(
      `${environment.apiUrl}/coupons/coupon-domains`,
      { ...data, ApplicationId: NIFTY_APPLICATIONID_KEY }
    );
  }

  updateDomain(
    id: string,
    data: {
      Domain: string;
      Description: string;
    }
  ) {
    return this.http.put<{ id: string }>(
      `${environment.apiUrl}/coupons/coupon-domains/${id}`,
      data
    );
  }

  activateDomain(id: string): Observable<void> {
    return this.http.patch<void>(
      `${environment.apiUrl}/coupons/coupon-domains/${id}/activate`,
      {}
    );
  }

  deactivateDomain(id: string): Observable<void> {
    return this.http.patch<void>(
      `${environment.apiUrl}/coupons/coupon-domains/${id}/deactivate`,
      {}
    );
  }

  assignDomainToCoupon(
    couponId: string,
    couponDomainId: string
  ): Observable<void> {
    return this.http.post<void>(
      `${this.BASE_COUPON_API}/${couponId}/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandAssignCouponDomainToCoupon}`,
      {
        couponDomainId,
      }
    );
  }

  unassignDomainToCoupon(
    couponId: string,
    couponDomainId: string
  ): Observable<void> {
    return this.http.post<void>(
      `${this.BASE_COUPON_API}/${couponId}/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandUnassignCouponDomainFromCoupon}`,
      {
        couponDomainId,
      }
    );
  }

  assignProductToCoupon(couponId: string, productId: string): Observable<void> {
    return this.http.post<void>(
      `${this.BASE_COUPON_API}/${couponId}/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandAssignProductToCoupon}`,
      {
        productId,
      }
    );
  }

  unassignProductFromCoupon(
    couponId: string,
    productId: string
  ): Observable<void> {
    return this.http.post<void>(
      `${this.BASE_COUPON_API}/${couponId}/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandUnassignProductFromCoupon}`,
      {
        productId,
      }
    );
  }

  assignBundleToCoupon(couponId: string, bundleId: string): Observable<void> {
    return this.http.post<void>(
      `${this.BASE_COUPON_API}/${couponId}/${API_SPECIFICATION_CONSTANTS.tell}/AssignBundleToCoupon`,
      {
        bundleId,
      }
    );
  }

  unassignBundleFromCoupon(
    couponId: string,
    bundleId: string
  ): Observable<void> {
    return this.http.post<void>(
      `${this.BASE_COUPON_API}/${couponId}/${API_SPECIFICATION_CONSTANTS.tell}/UnassignBundleFromCoupon`,
      {
        bundleId,
      }
    );
  }

  protected setUrlBody(coupon: Coupon) {
    this.logger.debug('set kit url body');
    const body = coupon;
    return body;
  }

  protected setCreateEntityBody(entity: Coupon, body: Partial<Coupon>) {
    const partialBody = {
      applicationId: NIFTY_APPLICATIONID_KEY,
    };

    body = Object.assign(body, partialBody);
    return body;
  }

  protected setUpdateEntityBody(entity: Coupon, body: Partial<Coupon>) {
    return body;
  }

  protected getListUrl(): string {
    this.logger.debug('get list url');
    return `${this.BASE_COUPON_DIRECTORY_API}/!/${API_SPECIFICATION_CONSTANTS.ask}/${COUPONS_API_CONSTANTS.commandListCoupons}`;
  }

  protected getUpdateEntityUrl(entityId: string): string {
    return `${this.BASE_COUPON_API}/${entityId}/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandUpdateCoupon}`;
  }
  protected getDeleteEntityUrl(entityId: string): string {
    return `${this.BASE_COUPON_API}/${entityId}/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandDeleteCoupon}`;
  }
  protected getCreateEntityUrl(): string {
    return `${this.BASE_COUPON_MANAGER_API}/!/${API_SPECIFICATION_CONSTANTS.tell}/${COUPONS_API_CONSTANTS.commandProvisionCoupon}`;
  }
  protected getFetchEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }
}
