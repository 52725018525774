import { Injectable } from '@angular/core';
import { Domain, Pagination } from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { COUPONS_PAGE_SORT_SETTINGS } from './../../../@shared/constants/coupons.constants';
import { Coupon, StatePageSettings } from './../../core.types';
import { CouponsService } from './../../services/coupons.service';
import {
  ActivateCouponDomainAction,
  AddCouponAction,
  ClearCouponsStateAction,
  CreateCouponDomainAction,
  DeactivateCouponDomainAction,
  DeleteCouponAction,
  LoadCouponById,
  LoadCouponDomainByIdAction,
  LoadCouponDomainsAction,
  LoadCouponsAction,
  RevokeCouponAction,
  SetCouponsById,
  UpdateCouponAction,
  UpdateCouponBundles,
  UpdateCouponDomainAction,
  UpdateCouponDomains,
  UpdateCouponProducts,
} from './coupons.actions';
import { NbToastrService } from '@nebular/theme';

const COUPONS_STATE_NAME = 'coupons';

export const COUPONS_LIST_PAGE_SIZE = 30;

export interface CouponsListFilterSettings {
  partnerId?: string;
  barcode?: string;
  status?: string;
  statusUpdate?: string;
  expiresDate?: string;
  laboratory?: string;
  country?: string;
  State?: string;
  selectedRuns?: {
    runsIds: string[];
    kitsIds: string[];
  };
}

export interface CouponsStateModel {
  coupons: { [id: string]: Coupon };
  domains: { [id: string]: Domain };
  domainsList?: {
    items: string[];
  };
  pages?: {
    items: string[];
    continuationToken: string;
  }[];
  currentPageSettings: StatePageSettings<CouponsListFilterSettings>;
  totalCount?: number;
}

const COUPONS_DEFAULT_STATE: CouponsStateModel = {
  coupons: {},
  domains: {},
  domainsList: {
    items: [],
  },
  pages: [],
  currentPageSettings: {
    pageIndex: 0,
    sortSettings: COUPONS_PAGE_SORT_SETTINGS,
    filterSettings: {
      State: '',
    },
  },
  totalCount: 0,
};

@State<CouponsStateModel>({
  name: COUPONS_STATE_NAME,
  defaults: {
    ...COUPONS_DEFAULT_STATE,
  },
})
@Injectable()
export class CouponsState {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    protected couponsService: CouponsService,
    protected store: Store,
    protected toastr: NbToastrService
  ) {}

  @Selector()
  static getCouponsState(state: CouponsStateModel) {
    return state.pages;
  }

  @Selector()
  static getPageState(state: CouponsStateModel) {
    return state;
  }

  @Selector()
  static getCouponsPage(state: CouponsStateModel) {
    return (page: number) => {
      if (state.pages[page]) {
        return state.pages[page];
      }
    };
  }

  @Selector()
  static getNumberOfLoadedPages$(state: CouponsStateModel) {
    return Object.keys(state.pages).map((x) => x);
  }

  @Selector()
  static getCouponById({ coupons: couponsState }: CouponsStateModel) {
    return (couponId: string) => couponsState[couponId];
  }

  @Selector()
  static getCurrentPage(state: CouponsStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;

    if (pages.length === 0 || pageIndex > pages.length || pageIndex < 0) {
      return [];
    }
    return state.pages[pageIndex].items;
  }

  @Selector()
  static getPaginationInfo({
    currentPageSettings,
    totalCount,
    pages,
  }: CouponsStateModel): Pagination<any> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: pages.length,
      collectionSize: COUPONS_LIST_PAGE_SIZE,
    };
  }

  @Selector()
  static getDomains(state: CouponsStateModel) {
    return state.domains;
  }

  @Selector([CouponsState.getDomains])
  static getDomainByStatus(
    state: CouponsStateModel,
    domains: { [id: string]: Domain }
  ) {
    return (deactivated = false) =>
      Object.values(domains).filter(
        (domain) => !!domain.deactivated === deactivated
      );
  }

  @Selector()
  static getDomainById({ domains }: CouponsStateModel) {
    return (domainId: string) => domains[domainId];
  }

  @Action(LoadCouponsAction)
  loadCouponsAction(
    { getState, patchState, dispatch }: StateContext<CouponsStateModel>,
    {
      options: { pageToLoad, orderDirection, orderBy, filter },
      refresh,
    }: LoadCouponsAction
  ) {
    this.log.debug('LoadCouponsAction');
    pageToLoad = pageToLoad || null;
    filter = filter || {};

    const {
      currentPageSettings: { sortSettings, pageIndex, filterSettings },
      pages,
    } = getState();
    let isReloadRequired = refresh || (!pageToLoad && pages.length === 1);

    if (orderBy) {
      if (orderBy !== sortSettings.orderBy) {
        isReloadRequired = true;
      }
    } else {
      orderBy = sortSettings.orderBy;
    }

    if (filterSettings.State !== filter.State) {
      isReloadRequired = true;
    }

    if (orderDirection) {
      if (orderDirection !== sortSettings.sortDirection) {
        isReloadRequired = true;
      }
    } else {
      orderDirection = sortSettings.sortDirection;
    }

    const filters = new Map<string, string>();
    for (const key of Object.keys(filter)) {
      filters.set(key, filter[key]);
    }

    filters.set(
      'CouponDomains',
      Object.values(getState().domains)
        .map((domain) => domain.id)
        .join(',')
    );

    const clearOrSkip$: Observable<any> = isReloadRequired
      ? dispatch(new ClearCouponsStateAction())
      : of(null);

    let continuationToken = null;
    if (pageToLoad !== null) {
      if (pageToLoad - 1 === pageIndex + 1) {
        continuationToken = pages[pageIndex].continuationToken;
      }

      if (!isReloadRequired) {
        if (pages.length >= pageToLoad) {
          return of(
            patchState({
              currentPageSettings: {
                ...getState().currentPageSettings,
                pageIndex: pageToLoad - 1,
              },
            })
          );
        }
      }
    }

    const loadedCoupons$: Observable<any> =
      this.couponsService.getFilteredEntities({
        continuationToken,
        orderBy,
        orderDirection,
        top: COUPONS_LIST_PAGE_SIZE,
        filters,
      });

    clearOrSkip$
      .pipe(
        switchMap(() =>
          loadedCoupons$.pipe(
            tap(
              ({
                entitiesList,
                newContinuationToken,
                totalCount,
              }: {
                entitiesList;
                totalCount;
                newContinuationToken;
              }) => {
                const coupons = { ...getState().coupons };
                for (const coupon of entitiesList) {
                  coupon.domains = coupon.domains || [];
                  coupons[coupon.id] = coupon;
                }
                return of(
                  patchState({
                    currentPageSettings: {
                      sortSettings: {
                        sortDirection: orderDirection,
                        orderBy,
                      },
                      filterSettings: filter,
                      pageIndex: pageToLoad !== null ? pageToLoad - 1 : 0,
                    },
                    totalCount,
                    pages: [
                      ...getState().pages,
                      {
                        items: entitiesList.map((t) => t.id),
                        continuationToken: newContinuationToken,
                      },
                    ],
                    coupons,
                  })
                );
              }
            )
          )
        )
      )
      .subscribe();
  }

  @Action(LoadCouponDomainsAction)
  loadDomains(
    { getState, patchState }: StateContext<CouponsStateModel>,
    // eslint-disable-next-line no-empty-pattern
    {}: LoadCouponDomainsAction
  ) {
    const domains$: Observable<any> = this.couponsService.loadDomains();

    return domains$.pipe(
      tap((domainsResponse: Domain[]) => {
        const domains = { ...getState().domains };
        const domainsPage = [];
        for (const domain of domainsResponse) {
          if (
            domain.domain.includes('nipt.') ||
            domain.domain.includes('nipt-')
          ) {
            domains[domain.id] = domain;
            domainsPage.push(domain.id);
          }
        }
        return of(
          patchState({
            domainsList: {
              items: domainsPage,
            },
            domains: { ...getState().domains, ...domains },
          })
        );
      })
    );
  }

  @Action(LoadCouponDomainByIdAction)
  loadDomain(
    { getState, patchState }: StateContext<CouponsStateModel>,
    { id }: LoadCouponDomainByIdAction
  ) {
    return this.couponsService.loadDomain(id).pipe(
      tap((domain) => {
        patchState({
          domains: { ...getState().domains, [id]: domain },
        });
      })
    );
  }

  @Action(LoadCouponById)
  loadCoupon(
    { getState, patchState }: StateContext<CouponsStateModel>,
    { couponId, refresh, prepend }: LoadCouponById
  ) {
    const { coupons } = getState();
    if (coupons[couponId] && !refresh) {
      return;
    }
    const pages = [...getState().pages];
    if (prepend) {
      pages[0] = {
        items: [couponId, ...pages[0].items],
        continuationToken: pages[0].continuationToken,
      };
    }

    return this.couponsService.getCouponById(couponId).pipe(
      tap((coupon) => {
        patchState({
          pages,
          coupons: { ...getState().coupons, [couponId]: coupon },
        });
      })
    );
  }

  @Action(CreateCouponDomainAction)
  createCouponDomain(
    { getState, patchState, dispatch }: StateContext<CouponsStateModel>,
    { data }: CreateCouponDomainAction
  ) {
    const postData = { Description: data.description, Domain: data.domain };
    return this.couponsService.createDomain(postData).pipe(
      catchError((err) => {
        this.handleProvisionError(err);
        throw err;
      }),
      delay(200),
      switchMap((response) =>
        dispatch(new LoadCouponDomainByIdAction(response.id))
      )
    );
  }

  @Action(UpdateCouponDomainAction)
  updateCouponDomain(
    { getState, patchState, dispatch }: StateContext<CouponsStateModel>,
    { id, data }: UpdateCouponDomainAction
  ) {
    const postData = { Description: data.description, Domain: data.domain };
    return this.couponsService.updateDomain(id, postData).pipe(
      catchError((err) => {
        this.handleProvisionError(err);
        throw err;
      }),
      delay(200),
      switchMap((response) =>
        dispatch(new LoadCouponDomainByIdAction(response.id))
      )
    );
  }

  @Action(DeactivateCouponDomainAction)
  deactivateCouponDomain(
    { getState, patchState, dispatch }: StateContext<CouponsStateModel>,
    { id }: DeactivateCouponDomainAction
  ) {
    return this.couponsService.deactivateDomain(id).pipe(
      delay(200),
      switchMap(() => dispatch(new LoadCouponDomainByIdAction(id)))
    );
  }

  @Action(ActivateCouponDomainAction)
  activateCouponDomain(
    { getState, patchState, dispatch }: StateContext<CouponsStateModel>,
    { id }: ActivateCouponDomainAction
  ) {
    return this.couponsService.activateDomain(id).pipe(
      delay(200),
      switchMap(() => dispatch(new LoadCouponDomainByIdAction(id)))
    );
  }

  @Action(DeleteCouponAction)
  deleteCoupon(
    { getState, patchState }: StateContext<CouponsStateModel>,
    { couponId }: DeleteCouponAction
  ) {
    return this.couponsService.deleteEntity(couponId).pipe(
      switchMap(() => {
        const currentState = getState();
        const pageWithCoupon = currentState.pages.findIndex((p) =>
          p.items.includes(couponId)
        );
        const pagesWithoutCoupon = [...currentState.pages];
        pagesWithoutCoupon[pageWithCoupon] = {
          ...currentState.pages[pageWithCoupon],
          items: currentState.pages[pageWithCoupon].items.filter(
            (i) => i !== couponId
          ),
        };
        const coupons = currentState.coupons
          ? {
              ...currentState.coupons,
            }
          : {};
        // eslint-disable-next-line no-prototype-builtins
        if (Object.prototype.hasOwnProperty.call(coupons, couponId)) {
          delete coupons[couponId];
          return of(
            patchState({
              coupons,
              pages: pagesWithoutCoupon,
              totalCount: getState().totalCount - 1,
            })
          );
        }
        return of({});
      })
    );
  }

  @Action(RevokeCouponAction)
  revokeCoupon(
    { getState, patchState, dispatch }: StateContext<CouponsStateModel>,
    { coupon }: RevokeCouponAction
  ) {
    return this.couponsService.revokeCoupon(coupon.id).pipe(
      switchMap(() => {
        const storeCoupons = getState().coupons[coupon.id];
        const filter = getState().currentPageSettings.filterSettings;
        if (storeCoupons) {
          return dispatch(
            new LoadCouponsAction(
              {
                filter,
                pageToLoad: 0,
              },
              true
            )
          );
        }
        return of(null);
      })
    );
  }

  @Action(ClearCouponsStateAction)
  clearCouponsState({ patchState }: StateContext<CouponsStateModel>) {
    this.log.debug('ClearCouponsStateAction');
    const { pages, currentPageSettings, totalCount } = COUPONS_DEFAULT_STATE;
    patchState({
      pages,
      currentPageSettings,
      totalCount,
    });
  }

  @Action(AddCouponAction)
  addCoupon(
    { dispatch }: StateContext<CouponsStateModel>,
    { payload: newCoupon }: AddCouponAction
  ) {
    const domains = [...newCoupon.couponDomains];
    const products = [...newCoupon.products];
    const bundles = [...newCoupon.bundles];
    delete newCoupon['couponDomains'];
    delete newCoupon['products'];
    delete newCoupon['bundles'];
    return this.couponsService.createNewEntity(newCoupon).pipe(
      delay(500),
      switchMap(() =>
        this.couponsService
          .findCoupon(newCoupon.code)
          .pipe(tap((c) => (newCoupon.id = c.id)))
      ),
      switchMap(() =>
        forkJoin([
          dispatch(new UpdateCouponProducts(newCoupon.id, products)),
          dispatch(new UpdateCouponBundles(newCoupon.id, bundles)),
          dispatch(new UpdateCouponDomains(newCoupon.id, domains)),
        ])
      ),
      delay(500),
      switchMap(() => dispatch(new LoadCouponById(newCoupon.id, true, true)))
    );
  }

  @Action(UpdateCouponAction)
  updateCoupon(
    { dispatch }: StateContext<CouponsStateModel>,
    { payload: { coupon } }: UpdateCouponAction
  ) {
    const domains = [...coupon.couponDomains];
    const products = [...coupon.products];
    const bundles = [...coupon.bundles];
    delete coupon['couponDomains'];
    delete coupon['products'];
    delete coupon['bundles'];
    return this.couponsService.updateEntity(coupon).pipe(
      delay(500),
      switchMap(() =>
        forkJoin([
          dispatch(new UpdateCouponProducts(coupon.id, products)),
          dispatch(new UpdateCouponBundles(coupon.id, bundles)),
          dispatch(new UpdateCouponDomains(coupon.id, domains)),
        ])
      ),
      delay(500),
      switchMap(() => dispatch(new LoadCouponById(coupon.id, true)))
    );
  }

  @Action(UpdateCouponProducts)
  updateCouponProducts(
    { getState }: StateContext<CouponsStateModel>,
    { couponId, newProducts }: UpdateCouponProducts
  ) {
    const { coupons } = getState();
    const coupon = coupons[couponId] ?? { products: [] };
    const addProducts = newProducts.filter(
      (productId) => !coupon.products.includes(productId)
    );
    const removeProducts = coupon.products.filter(
      (productId) => !newProducts.includes(productId)
    );

    return forkJoin([
      of(null),
      ...addProducts.map((productId) =>
        this.couponsService.assignProductToCoupon(couponId, productId)
      ),
      ...removeProducts.map((productId) =>
        this.couponsService.unassignProductFromCoupon(couponId, productId)
      ),
    ]);
  }

  @Action(UpdateCouponDomains)
  updateCouponDomains(
    { getState }: StateContext<CouponsStateModel>,
    { couponId, newDomains }: UpdateCouponDomains
  ) {
    const { coupons } = getState();
    const coupon = coupons[couponId] ?? { couponDomains: [] };
    const addDomains = newDomains.filter(
      (domainId) => !coupon.couponDomains.includes(domainId)
    );
    const removeDomains = coupon.couponDomains.filter(
      (domainId) => !newDomains.includes(domainId)
    );

    return forkJoin([
      of(null),
      ...addDomains.map((domainId) =>
        this.couponsService.assignDomainToCoupon(couponId, domainId)
      ),
      ...removeDomains.map((domainId) =>
        this.couponsService.unassignDomainToCoupon(couponId, domainId)
      ),
    ]);
  }

  @Action(UpdateCouponBundles)
  updateCouponBundles(
    { getState }: StateContext<CouponsStateModel>,
    { couponId, newBundles }: UpdateCouponBundles
  ) {
    const { coupons } = getState();
    const coupon = coupons[couponId] ?? { bundles: [] };
    const addBundles = newBundles.filter(
      (bundleId) => !coupon.bundles.includes(bundleId)
    );
    const removeBundles = coupon.bundles.filter(
      (bundleId) => !newBundles.includes(bundleId)
    );

    return forkJoin([
      of(null),
      ...addBundles.map((bundleId) =>
        this.couponsService.assignBundleToCoupon(couponId, bundleId)
      ),
      ...removeBundles.map((bundleId) =>
        this.couponsService.unassignBundleFromCoupon(couponId, bundleId)
      ),
    ]);
  }

  @Action(SetCouponsById)
  setCouponsById(
    { getState, patchState }: StateContext<CouponsStateModel>,
    { coupons }: SetCouponsById
  ) {
    const couponsArray: Coupon[] = Array.isArray(coupons) ? coupons : [coupons];
    if (couponsArray.length) {
      const { coupons: stateCoupons } = getState();
      const updatedCouponsState = stateCoupons ? { ...stateCoupons } : {};
      for (const coupon of couponsArray) {
        updatedCouponsState[coupon.id] = coupon;
      }

      patchState({
        coupons: { ...updatedCouponsState },
      });
    }
  }

  private handleProvisionError(err) {
    this.toastr.danger(err?.error?.Title, '', { duration: 0 });
  }
}
